<template>
  <div class="div_class col-md-3 itens px-0" v-if="item">
    <div class="box">
      <div :class="$route.name === 'unidades' ? 'row d-flex align-items flex-column mudarTamanho' : 'row d-flex align-items flex-column rankCont'"  >
        <div class="rank">
          <img
          v-if="item.ranking < 4"
          :src="require(`@/assets/image/metas/Selo${item.ranking}.svg`)"
            alt="Selo1.svg"
          />
        </div>
        <div class="col-md-6 p-2 mx-auto position-relative" id="vendGaugCont">
          <VueSvgGauge
            :start-angle="-183"
            :end-angle="177"
            :value="aroundMath(item.dashboardUser.userScore)"
            :separator-step="0"
            :separatorThickness="0"
            :min="0"
            :max="100"
            baseColor="#E3E3E3"
            :scale-interval="0"
            :gauge-color="[
              { offset: 0, color: '#FED990' },
              { offset: 50, color: '#E99637' },
              { offset: 100, color: '#F38235' }
            ]"
            :transition-duration="2500"
          >
            <div
              class="inner-text d-flex justify-content-center h-100 align-items-center"
            >
              <p
                class="mb-0"
                style="font-size: 30px;"
                v-if="porceto === 'Percent'"
                key="porcento"
              >
                <span>{{ aroundMathFixed(item.dashboardUser.userScore) }}</span>
                <small>%</small>
              </p>
              <p
                class="mb-0"
                v-else-if="porceto === 'money'"
                key="reais"
                style="font-size: 29px;"
              >
                <span>{{
                  valueConvert(item.dashboardUser.userOverallSold)
                }}</span>
              </p>
            </div>
          </VueSvgGauge>
        </div>
        <div
          class="d-flex align-items-center justify-content-around mt-3 containerMath"
        >
          <div class="containerCircle d-flex align-items-center">
            <div class="circle mr-2"></div>
            <span>{{ aroundMathFixed(item.dashboardUser.userScore) }}</span>
            <small>%</small>
          </div>
          <div class="containerCircle d-flex align-items-center">
            <div class="circle whiteCircle mr-2"></div>
            <span>{{
              item.dashboardUser.userScore > 100 ? 0 : roundFn( 100 - item.dashboardUser.userScore, 1)
            }}</span>
            <small>%</small>
          </div>
        </div>
        <div class="d-flex pd align-items-center ">
          <div class="image_div">
            <img
              :src="getImg(item.dashboardUser.userPhoto)"
              class="img_user"
              @error="errorImage"
            />
          </div>
          <div class="col p-0 d-flex justify-content-center align-items-center">
            <p class="meta_p mb-0 text-center">
              <strong>{{ item.dashboardUser.userName }}</strong> atingiu
              <strong>{{ aroundMath(item.dashboardUser.userScore) }}%</strong>
              da meta total do mês.
            </p>
          </div>
          <div class="menu-dots">
            <div class="dropdown dropleft">
              <button class="btnDots" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false">
                <img src="@/assets/image/icones/buttons/dots-vertical.svg" alt="dots" />
              </button>
              <div class="dropdown-menu p-0 positionDrop" aria-labelledby="dropdownMenu2">
                <div class="d-flex align-items-center justify-content-between p-2 divBtn" @click="$emit('openModalGoalUser', item)">
                  <p class="m-0">Atualizar meta</p>
                  <img src="@/assets/image/icones/buttons/edit.svg" alt="edit" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["item", "porceto"],
  data() {
    return {
      sizeWindow: 0,
      salesman: {}
    };
  },
  mounted() {
    this.sizeWindow = $(window).width();
    this.salesman = this.item;
  },
  watch: {
    item: function() {}
  }
};
</script>

<style scoped>
.bottom_right {
  bottom: 0px;
  right: -20px;
}

.div_class {
  min-width: 250px;
  margin-right: 10px;
}

.box {
  border-radius: 12px;
  background-color: #ffffff;
}

.meta_p {
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #2474b2 !important;
  text-align: initial;
}

small {
  font-size: 20px;
}

.rank {
  padding: 10px 30px 0;
}

.inner-text p {
  font-size: 38px;
  color: #2474b2;
  font-weight: bold;
}

.circle {
  height: 13px;
  width: 13px;
  border-radius: 50%;
  background: linear-gradient(270deg, #e0a638 0%, #f38235 100%);
}

.whiteCircle {
  background: #e9e9eb !important;
}

.containerCircle span,
.containerCircle small {
  font-weight: 700;
  font-size: 12px;
  color: #2474b2 !important;
}

.containerMath {
  border-bottom: 2px solid #e9e9eb;
  width: 80%;
  margin: 0 auto;
  padding: 0px 15px 8px;
}

.image_div {
  border-radius: 50%;
  border: 3px solid #2474b2;
  height: 55px;
  width: 55px;
  overflow: hidden;
  margin-right: 10px;
}

.image_div .img_user {
  object-fit: cover;
  min-height: 100%;
  width: 100%;
  object-position: top;
}

.userValue p {
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #2474b2 !important;
  text-align: initial;
}
.pd {
  padding: 15px 30px 15px;
}
@media screen and (max-width:767px){
  .rankCont{
    height: 310px;
    flex-direction: unset!important;
  }
  .mudarTamanho{
    height: 375px;
    flex-direction: unset!important;
  }
  #vendGaugCont{
    width: 70%;
    margin-left: 0!important;
  }
  .rank{
    width: 10%;
    padding: 10px 23px 0;
  }
  .image_div {
  height: 35px;
  width: 35px;
  }
  .div_class .box{
    max-width: 250px;
  }
}
.btnDots {
  background: #f9f9f9;
  border: none;
  outline: none;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  transition: 0.1s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btnDots:hover {
  background: #f0ececfd;
}

.positionDrop {
  top: -18px !important;
  left: 2px !important;
}

.divBtn {
  cursor: pointer;
}
.menu-dots {
  margin-left: 10px;
}
</style>
