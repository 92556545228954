<template>
  <div class="div_class col-md-3 itens px-0 m-2">
    <div class="box">
      <div class="d-flex align-items-center justify-content-center">
        <strong class="textTenancyName space">{{ item.name }}</strong>
      </div>
      <div class="row d-flex align-items flex-column">
        <div :class="item.tenacyRanking < 4 ? 'rank' : 'rankNone'">
          <img v-if="item.tenacyRanking < 4" :src="require(`@/assets/image/metas/Selo${item.tenacyRanking}.svg`)"
            alt="Selo1.svg" />
        </div>
      </div>
      <div class="row align-items-center mx-0 h-100">
        <div class="col-md-7 p-2 mx-auto position-relative boxSize">
          <!-- :value="aroundMathFixed(item.tenancyScore)" Função anterior-->
          <VueSvgGauge :start-angle="-183" :end-angle="177" :value="aroundMath(item.tenancyScore)" :separator-step="0"
            :separatorThickness="0" :min="0" baseColor="#E3E3E3" :max="100" :scale-interval="0" :gauge-color="[
              { offset: 0, color: '#FED990' },
              { offset: 50, color: '#E99637' },
              { offset: 100, color: '#F38235' }
            ]" :transition-duration="2500">
            <div class="inner-text d-flex justify-content-center h-100 align-items-center">
              <p class="mb-0" style="font-size: 30px;" v-if="porceto === 'Percent'" key="porcento">
                <span>{{ aroundMathFixed(item.tenancyScore) }}</span>
                <small>%</small>
              </p>
              <p class="mb-0" v-else-if="porceto === 'money'" key="reais" style="font-size: 29px;">
                <span>{{ valueConvert(item.tenancyOverallSold) }}</span>
              </p>
            </div>
          </VueSvgGauge>
        </div>
        <div class="d-flex align-items-center justify-content-around mt-3 containerMath">
          <div class="w-65 d-flex m-auto">
            <div class="containerCircle d-flex align-items-center pl-2 pr-3">
              <div class="circle mr-2"></div>
              <span>{{ aroundMathFixed(item.tenancyScore) }}</span>
              <small>%</small>
            </div>
            <div class="containerCircle d-flex align-items-center pr-2">
              <div class="circle whiteCircle mr-2"></div>
              <span>{{
                  item.tenancyScore > 100
                    ? 0
                    : roundFn(100 - item.tenancyScore, 1)
              }}</span>
              <small>%</small>
            </div>
          </div>
        </div>
        <div class="col-md-12 mx-auto d-flex m-0 align-items-center justify-content-between pr-3 pl-2 pb-3 pt-3">
          <div class="d-flex ">
            <div class="image_div mr-2">
              <img :src="getImg(item.franchisePhoto)" class="img_user" @error="errorImage" />
            </div>
            <p class="meta_p m-0 ">
              Franqueado
              <br />
              <strong class="strongName">{{
                  nameFran(item.franchiseDisplayName)
              }}</strong>
            </p>
          </div>
          <div class="position-relative">
            <div class="dropdown dropleft">
              <button class="btnDots" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false">
                <img src="@/assets/image/icones/buttons/dots-vertical.svg" alt="dots" />
              </button>
              <div class="dropdown-menu p-0 positionDrop" aria-labelledby="dropdownMenu2">
                <div class="d-flex align-items-center justify-content-between p-2 divBtn"
                  @click="$emit('enterTenant', item)">
                  <p class="m-0">Entrar</p>
                  <img src="@/assets/image/icones/buttons/Openunidade.svg" alt="Openunidade" />
                </div>
                <div class="d-flex align-items-center justify-content-between p-2 divBtn" @click="$emit('edit', item)">
                  <p class="m-0">Editar</p>
                  <img src="@/assets/image/icones/buttons/edit.svg" alt="edit" />
                </div>
              </div>
            </div>
            <!-- <Dropdown class="position-absolute" /> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dropdown from "./dropdown.vue";

export default {
  props: ["item", "porceto"],
  components: {
    Dropdown
  },
  // mounted() {
  //   console.log(this.item);
  // },
  methods: {
    nameFran(val) {
      const str = val.split(" ");

      if (str.length > 1 && str[1].length > 1) {
        return `${str[0]} ${str[1]}`;
      } else if (str.length > 1 && str[1].length === 1) {
        return `${str[0]} ${str[1]} ${str[2]}`;
      }
      return `${str[0]}`;
    }
  }
};
</script>

<style scoped>
small {
  font-size: 20px;
}

.strongName {
  color: #f38235;
  font-weight: 700;
  font-size: 16px;
}

.bottom_right {
  bottom: 0px;
  right: -20px;
}

.div_class {
  margin-right: 10px;
  /* min-width: 230px;
    margin-right: 10px; */
}

.box {
  border-radius: 12px;
  min-height: 268px;
  background-color: #ffffff;
  width: 285px;
  margin: 0 auto;
}

.textTenancyName {
  color: #2474b2;
  font-weight: 700;
  font-size: 18px;
  position: relative;
  top: 20px;
}

.meta_p {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #3f88c1;
  text-align: initial;
}

.rank {
  padding: 0px 30px 0;
}

.inner-text p {
  font-size: 38px;
  color: #2474b2;
  font-weight: bold;
}

.boxSize {
  width: 70%;
}

.inner-text p {
  font-size: 38px;
  color: #2474b2;
  font-weight: bold;
}

.circle {
  height: 13px;
  width: 13px;
  border-radius: 50%;
  background: linear-gradient(270deg, #e0a638 0%, #f38235 100%);
}

.whiteCircle {
  background: #e9e9eb !important;
}

.containerCircle span,
.containerCircle small {
  font-weight: 700;
  font-size: 12px;
  color: #2474b2 !important;
}

.containerMath {
  border-bottom: 1px solid #e9e9eb;
  width: 100%;
  margin: 0 auto;
  padding: 0px 15px 8px;
}

.image_div {
  border-radius: 50%;
  border: 3px solid #2474b2;
  height: 35px;
  width: 35px;
  overflow: hidden;
}

.image_div .img_user {
  object-fit: cover;
  min-height: 100%;
  width: 100%;
  object-position: top;
}

.userValue p {
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #2474b2 !important;
  text-align: initial;
}

.pd {
  padding: 15px 30px 15px;
}

.rankNone {
  height: 24px;
}

.space {
  padding-top: 5px;
  height: 25px;
  max-width: 148px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btnDots {
  background: #f9f9f9;
  border: none;
  outline: none;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  transition: 0.1s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btnDots:hover {
  background: #f0ececfd;
}

.positionDrop {
  top: -18px !important;
  left: 2px !important;
}

.divBtn {
  cursor: pointer;
}

.divBtn:hover {
  background: rgba(199, 196, 196, 0.808);
  border-radius: 2px;
}

@media(min-width: 800px) {
  .box {
    min-width: 250px;
    width: auto;
  }

  .boxSize {
    width: auto;
  }
}
</style>
