<template>
  <div class="containerBox">
    <div class="dropdown">
      <button
        class="btn btn-secondary dropdown-toggle"
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        Dropdown button
      </button>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton"></div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.containerBox {
  z-index: 22;
  width: 151px;
  background: #fff;
  top: -7px;
  left: -145px;
  border-radius: 2px;
}

.containerBox p {
  font-weight: 400;
  font-size: 16px;
  color: #797984;
  margin: 0;
}

/* .dropdown.show:before {
   position: fixed;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   background: rgba(0, 0, 0, 0.253);
   content: '';
   z-index: 1;
} */
</style>
